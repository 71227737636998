<template>
  <div class="uk-grid-medium content-management-page" uk-grid>
    <div class="uk-width-5-5">
      <div class="d-flex">
        <nav id="breadcrumbs" class="mb-3">
          <ul>
            <li>
              <router-link :to="{ name: 'company.dashboard' }">
                <i class="uil-home-alt"></i>
              </router-link>
            </li>
            <li>{{ this.$t('menu.company_dashboard.content_approves') }}</li>
          </ul>
        </nav>
      </div>

      <div class="card">
        <!-- Card header -->
        <div class="card-header actions-toolbar border-0">
          <div class="d-flex justify-content-between align-items-center">
            <div class="row" style="width:100%">
              <div class=" col-md-1  search-icon ">
                <i class="uil-search"/>
              </div>
              <div class="col-md-11 uk-search uk-search-navbar" style="margin-left:-2rem">
                <input v-model="filter.search" :placeholder="$t('general.search')"
                       class="uk-search-input shadow-0 uk-form-small" type="search"
                       @input="debouncedSearch"/>
              </div>
            </div>
            <sort-dropdown :sort-options="sortOption" :title="sortTitle" @sort-selected="sortOrder"/>
          </div>
        </div>

        <!-- Table -->
        <div class="table-responsive">
          <table class="table align-items-center">
            <thead>
            <tr>
              <th scope="col">{{ $t('share_management.content.user') }}</th>
              <th scope="col">{{ $t('share_management.content.assign_name') }}</th>
              <th scope="col">{{ $t('share_management.content.mission_name') }}</th>
              <th scope="col">{{ $t('share_management.content.approved_by') }}</th>
              <th scope="col">{{ $t('share_management.content.approval_status') }}</th>
              <th scope="col">{{ $t('share_management.content.transactions') }}</th>
            </tr>
            </thead>
            <tbody class="list">
            <tr v-for="(userContent,index) in userContents" :key="'user_contents_' + index">
              <td>
                  <div class="media align-items-center">
                    <UserAvatarImage v-if="userContent.user?.photo_link" :photo="userContent.user.photo_link" />
                    <div class="media-body ml-4">
                      <a class="name h6 mb-0 text-sm disabled">{{
                          userContent.user?.fullname
                        }}</a>
                      <small class="d-block font-weight-bold">#{{ userContent.user?.id }}</small>
                    </div>
                  </div>
              </td>
              <td>{{ userContent.content?.title }}</td>
              <td>{{ userContent.assignment?.name }}</td>
              <td>{{ userContent.approved_by_user ? userContent.approved_by_user.fullname : '--' }}</td>
              <td class="approve-text-result">
                <template v-if="userContent.approved_at">
                  <i :class="userContent.is_correct?'icon-feather-check':'icon-feather-x'"/>
                  <span :class="userContent.is_correct?'approved':'rejected'">{{
                      getApproveStatus(userContent.is_correct)
                    }}</span>
                </template>
              </td>
              <td class="text-right">
                <div class="actions ml-3">
                  <button class="btn btn-icon btn-hover btn-sm btn-circle"
                          uk-tooltip="İncele" @click="openUserModal(userContent)">
                    <i class="uil-search-alt"></i>
                  </button>
                </div>
              </td>
            </tr>
            </tbody>
          </table>

          <pagination-tab v-if="pagination?.links" :pagination="pagination" @changePage="setPage($event)"/>

        </div>
      </div>
    </div>

    <!-- Modal -->
    <div id="user-action-content-modal" key="userModalContent" ref="userActionRef" class="uk-modal-container" uk-modal>
      <div class="uk-modal-dialog">
        <div class="uk-modal-header">
          <h2 class="uk-modal-title">{{ $t('share_management.content.manage_shares') }}</h2>
        </div>
        <!-- <button class="uk-modal-close-default" type="button" uk-close></button> -->

        <div class="uk-modal-body">
          <form v-if="Object.keys(userModalContent).length>0" class="uk-form-stacked">
            <div class="uk-form-group row">
              <div class="uk-margin col-md-4">
                <label class="uk-form-label">{{ $t('share_management.content.user') }}:</label>
                <div class="uk-form-controls">
                  <!-- <input :value="userModalContent.user?.fullname" class="uk-input" disabled> -->
                  <p class="m-0">{{ userModalContent.user?.fullname }}</p>
                </div>
              </div>
              <div class="uk-margin col-md-4 mt-0">
                <label class="uk-form-label">{{ $t('share_management.content.date') }}:</label>
                <div class="uk-form-controls">
                  <!-- <input :value="getApprovedDate(userModalContent.approved_at)" class="uk-input" disabled> -->
                  <p class="m-0">{{ getApprovedDate(userModalContent.approved_at) }}</p>
                </div>
              </div>
              <div class="uk-margin col-md-4 mt-0">
                <label class="uk-form-label">{{ $t('share_management.content.answer_type') }}:</label>
                <div class="uk-form-controls">
                  <!-- <input :value="getAnswerType(userModalContent.content_mission?.answer_type)" class="uk-input" disabled> -->
                  <p class="m-0">{{ getAnswerType(userModalContent.content_mission?.answer_type) }}</p>
                </div>
              </div>

              <div class="uk-margin col-md-4">
                <label class="uk-form-label">{{ $t('share_management.content.assign_name') }}:</label>
                <div class="uk-form-controls">
                  <!-- <input :value="userModalContent.assignment?.name" class="uk-input" disabled> -->
                  <p class="m-0">{{ userModalContent.assignment?.name }}</p>
                </div>
              </div>
              <div class="uk-margin col-md-4">
                <label class="uk-form-label">{{ $t('share_management.content.content_name') }}:</label>
                <div class="uk-form-controls">
                  <!-- <input :value="userModalContent.content?.title" class="uk-input" disabled> -->
                  <p class="m-0">{{userModalContent.content?.title}}</p>
                </div>
              </div>
              <div class="uk-margin col-md-4">
                <label class="uk-form-label">{{ $t('share_management.content.completion_rule') }}:</label>
                <div class="uk-form-controls">
                  <!-- <input :value="getCompletionRule(userModalContent.content_mission?.completion_rule)" class="uk-input" disabled> -->
                  <p class="m-0">{{ getCompletionRule(userModalContent.content_mission?.completion_rule) }}:</p>
                </div>
              </div>
              <div class="uk-margin col-md-12 highlight">
                <label class="uk-form-label">{{ $t('share_management.content.task_description') }}:</label>
                <div class="uk-form-controls">
                  <!-- <input :value="userModalContent.content_mission?.mission_description" class="uk-input" disabled> -->
                  <p class="m-0">{{ userModalContent.content_mission?.mission_description }}</p>
                </div>
              </div>
              <div class="uk-margin col-md-12 highlight mt-0">
                <label class="uk-form-label">{{ $t('share_management.content.answer_given') }}:</label>
                <div class="uk-form-controls">
                  <!-- <input :value="userModalContent.answer??'--'" class="uk-input" disabled> -->
                  <p class="m-0">{{ userModalContent.answer??'--' }}</p>
                </div>
              </div>
              <div v-if="userModalContent.file" class="uk-margin col-md-4">
                <label class="uk-form-label">{{ $t('share_management.content.file') }}:</label>
                <div class="uk-form-controls file-link">
                  <p @click="gotoFileLink(userModalContent)" class="m-0">{{ $t('share_management.content.file_link') }}</p>
                </div>
              </div>

              <div class="uk-margin col-md-4">
                <label class="uk-form-label">{{ $t('share_management.content.right_answer') }}:</label>
                <div class="uk-form-controls">
                  <!-- <input :value="userModalContent.content_mission?.accepted_right_answer??'--'" class="uk-input" disabled> -->
                  <p class="m-0">{{ userModalContent.content_mission?.accepted_right_answer??'--'}}</p>
                </div>
              </div>

              <div class="uk-margin col-md-4">
                <label class="uk-form-label">{{ $t('share_management.content.approved_by') }}:</label>
                <div class="uk-form-controls">
                  <!-- <input :value="userModalContent.approved_by_user ? userModalContent.approved_by_user.fullname :'--'" class="uk-input" disabled> -->
                  <p class="m-0">{{ userModalContent.approved_by_user ? userModalContent.approved_by_user.fullname :'--' }}</p>
                </div>
              </div>
            </div>
          </form>    
        </div>
        <div class="uk-modal-footer uk-text-right">
            <button v-if="!userModalContent.approved_at" class="uk-button uk-button-default uk-modal-close mr-2"
                    type="button" @click="acceptContentAnswer({type:'approve',user:userModalContent})">
                    <i class="uil-check-circle"></i> {{ $t('share_management.content.approve') }}
            </button>
            <button v-if="!userModalContent.approved_at" class="uk-button uk-button-default mr-2"
                    type="button" @click="refuseContentAnswer">
                    <i class="uil-times-circle"></i> {{ $t('share_management.content.decline') }}
            </button>
            <button class="uk-button uk-button-default uk-modal-close" type="button">
              {{ $t('share_management.content.close') }}
            </button>
          </div>

          <div v-if="isCancelInputActive" class="uk-modal-footer ">
            <div class="row">
              <div class="col-11 uk-search uk-search-navbar d-flex">
                <textarea v-model="cancelContentInput" :placeholder="$t('share_management.content.reject_reason')"
                          class="uk-textarea pt-2"/>
              </div>
              <div class="col-1">
                <button :disabled="!cancelContentInput.length"
                        class="uk-button uk-button-default uk-button-primary"
                        type="button" @click="acceptContentAnswer({type:'reject',user:userModalContent})">
                  {{ $t('share_management.content.send') }}
                </button>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import DataTable from "@/assets/components/dataTable/DataTable";
import PaginationTab from "@/assets/components/Pagination";
import SortDropdown from "@/view/components/dropdown/SortDropdown.vue";
import Pagination from "@/assets/components/Pagination.vue";
import UserAvatarImage from "@/view/components/image/user-avatar-image/user-avatar-image.vue";
import EventBus from "@/view/components/event-bus";

import _ from "lodash";
import axios from "axios";
import moment from "moment-timezone";

export default {
  name: "content",
  components: {
    UserAvatarImage,
    Pagination,
    DataTable,
    PaginationTab,
    SortDropdown
  },
  data() {
    return {
      pagination: null,
      page: 1,
      userModal: null,
      filter: {
        search: null,
        order: "desc",
        sort: "created_at",
      },
      userContents: [],
      userModalContent: {},

      sortTitle: this.$t("filters.order.sort"),
      cancelContentInput: '',
      isCancelInputActive: false,
      sortOption: [
        {key: 'text-asc', sort: 'id', order: 'asc', label: this.$t("sorting.a_z")},
        {key: 'text-desc', sort: 'id', order: 'desc', label: this.$t("sorting.z_a")},
        {key: 'created_at-asc', sort: 'created_at', order: 'asc', label: this.$t("sorting.created_at_asc")},
        {key: 'created_at-desc', sort: 'created_at', order: 'desc', label: this.$t("sorting.created_at_desc")},
      ],
    };
  },
  mounted() {
    this.fetchMissionManagement()

    this.userModal = UIkit.modal("#user-action-content-modal")
  },
  methods: {

    getCompletionRule(rule) {
      //rules => answered , admin_approved, correct_answer_given
      return this.$t(`share_management.content.${rule}`)
    },

    getAnswerType(answer) {
      //answers => file, text
      return this.$t(`share_management.content.${answer}`)
    },

    gotoFileLink(data) {
      if (data?.file) {
        window.open(data.file, '_blank');
      }
    },

    setPage(page) {
      let self = this;
      self.page = page;
      this.fetchMissionManagement();
    },

    resetModalState() {
      this.userModalContent = {};
      this.cancelContentInput = '';
      this.isCancelInputActive = false;
    },

    getApprovedDate(date) {
      if (!date) return '--';

      return moment(date).format('DD-MM-YYYY - hh:mm:ss')
    },

    sortOrder({sort, order}) {
      let self = this;
      self.page = 1;
      self.filter.order = order;
      self.filter.sort = sort;

      this.fetchMissionManagement()
    },

    async fetchMissionManagement() {
      const {data} = await axios.get(`api/mission-management/`, {
        params: {
          page: this.page,
          search: this.filter.search,
          order: this.filter.order,
          sort: this.filter.sort
        },
      })

      this.userContents = data?.data
      this.pagination = data?.meta

      await this.getUnProcessedMissionCount()
    },

    async getUnProcessedMissionCount(){
      const {data} = await axios.get(`api/mission-management/`, {
        params: {
          count:true
        },
      })

      // data - işlem yapılmamış content sayısını tutar
      EventBus.$emit("missionContentCount", data ?? 0);
    },

    async fetchMissionDetail({contentId}) {
      try {
        const {data} = await axios.get(`api/content/${contentId}`)
        return data?.content_mission
      } catch (e) {
        console.error("Error fetching content detail:", e);
      }
    },

    debouncedSearch: _.debounce(function () {
      this.page = 1;
      this.fetchMissionManagement();
    }, 500),

    async openUserModal(content) {
      this.resetModalState()
      const content_mission = await this.fetchMissionDetail({contentId: content?.content.id})
      this.userModalContent = {...content, content_mission}
      this.userModal.show()
    },

    refuseContentAnswer() {
      this.isCancelInputActive = true
    },

    async acceptContentAnswer({user, type = 'approve'}) {
      try {
        const params = {
          is_correct: type === 'approve',
          ...(type === 'reject' && {reason: this.cancelContentInput}),
        };

        await axios.put(`api/mission-management/${user.id}`, params);
        await this.fetchMissionManagement()

        this.userModal.hide()

      } catch (error) {
        console.error("Error:", error);
      }
    },

    getApproveStatus(status) {
      return status ? this.$t('share_management.content.approved') : this.$t('share_management.content.rejected')
    }
  },

};
</script>
<style lang="scss" scoped>
.content-management-page {
  .approve-text-result {
    .approved {
      color: #49D052;
    }

    .rejected {
      color: #ce2b2b;
    }

    .icon-feather-check {
      color: #49D052 !important;
      margin-right: 4px;
    }

    .icon-feather-x {
      color: #ce2b2b !important;
      margin-right: 4px;
    }
  }
}

#user-action-content-modal {
  background-color: white;

  .uk-modal-dialog {
    height: 100%;
  }

  .file-link {
    cursor: pointer;
    text-decoration: underline;
  }

  .highlight{
    padding:1rem;
    background: lightcyan;
  }
}
</style>
