<template>
  <div class="sort-dropdown">
    <a :uk-tooltip="title" aria-expanded="false" class="btn btn-icon btn-hover btn-circle" href="#">
      <i class="uil-list-ui-alt" />
    </a>
    <div id="sort-dropdown_modal" ref="dropdownModalRef" class="uk-dropdown"
        uk-dropdown="pos: bottom-right; mode: click; animation: uk-animation-scale-up">
      <ul class="uk-nav uk-dropdown-nav">
        <li v-for="option in sortOptions" :key="option.key" @click="handleSort(option.sort, option.order)">
          <a href="javascript:void(0)">{{ option.label }}</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "SortDropdown",
  props: {
    sortOptions: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      default: "Sort Options",
    },
  },
  methods: {
    handleSort(sort, order) {
      this.$emit("sort-selected", {sort, order});

      const dropdown = this.$refs.dropdownModalRef;
      if (dropdown) {
        UIkit.dropdown(dropdown).hide();
      }
    },
  },
};
</script>

<style scoped>
.sort-dropdown {
  position: relative;
}
</style>
