<template>
  <div class="user-avatar-image">
    <div class="avatar-parent-child" style="width: max-content">
      <img :src="photo" alt="Image placeholder" class="avatar rounded-circle"/>
      <span class="avatar-child avatar-badge bg-success"/>
    </div>
  </div>
</template>

<script>
export default {
  name: "user-avatar-image",
  props: {
    photo: {
      type: String,
      required: true
    }
  }
}
</script>
